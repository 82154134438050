import { AtomAccordionLink } from 'atoms/AtomLink'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { ParagraphAccordionItemFeData } from 'components/DynamicComponent/map/ParagraphAccordionItemFe'
import { ParagraphDoorwaysBannerFe } from 'components/DynamicComponent/map/ParagraphDoorwaysBannerFe'
import { SearchIcon } from 'components/Icons/SearchIcon'
import { ParagraphSvgFeData } from 'components/DynamicComponent/map/ParagraphSvgFe'
import { Icon } from 'components/Icons/Icon'
import MarkupFaqHead from 'components/MarkupFaqHead/MarkupFaqHead'
import { LoaderModal } from 'components/Modale/LoaderModal/LoaderModal'
import Modal from 'components/Modale/Modal'
import { graphql } from 'gatsby'
import { CategoriesDomandeFrequentiType } from 'models/categories-structure-model'
import {
  StyledArgomentiTitle,
  StyledArgomentiWrapper,
  StyledArgomentoChildrenWrapper,
  StyledArgomentoItem,
  StyledArgomentoLink,
  StyledArgumentChild,
  StyledArgumentChildLink,
  StyledBannerWrapper,
  StyledCategoriaWrapper,
  StyledChildrenWrapper,
  StyledContentWrapper,
  StyledDesktopMenu,
  StyledMobileMenu,
  StyledMobileMenuButton,
  StyledSearchComponent,
  StyledSearchComponentInput,
  StyledSearchComponentInputIcon,
  StyledTitleWrapper,
} from 'pages-style/categoria.style'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { Grid } from '../commons/Grid'
import {
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
  removeTrailingSlashes,
} from '../commons/utils'
import { Html } from '../components/App/Html'
import {
  Accordion,
  AccordionItem,
  AccordionTheme,
} from '../components/UI/Accordion'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { useFeedbackQA } from '../hooks/use-feedback-qa'
import { FeedbackFAQ } from '../components/FeedbackFAQ/FeedbackFAQ'
import { ParagraphAccordionFeData } from 'components/DynamicComponent/map/ParagraphAccordionFe'
import { isParagraphTestoLibero } from 'components/DynamicComponent/map/ParagraphTestoLiberoFe'
import classNames from 'classnames'
import { StyledFeedbackFAQWrapper } from 'components/FeedbackFAQ/FeedbackFAQ.style'
import { HeroImage } from 'components/HeroImage/HeroImage'
import useSWR from 'swr'
import { PlusIconTransparent } from 'components/Icons/PlusIcon'

const SearchInputComponent = ({ show }: { show?: boolean }) => {
  const [searchTerm, setSearchTerm] = useState('')

  if (!show) {
    return null
  }

  return (
    <StyledSearchComponent>
      <StyledSearchComponentInput
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            window.location.href = `/assistenza/faq/?keys=${searchTerm}`
          }
        }}
        type="text"
        placeholder="Digita la tua parola chiave"
      />
      <StyledSearchComponentInputIcon
        onClick={(e) => {
          e.preventDefault()
          if (searchTerm.length === 0) {
            return
          }
          window.location.href = `/assistenza/faq/?keys=${searchTerm}`
        }}
      >
        <Icon width="24px" Icon={SearchIcon} stroke="inherit" />
      </StyledSearchComponentInputIcon>
    </StyledSearchComponent>
  )
}

function scrollToHash() {
  const less1024 = window.matchMedia('(max-width: 1023px)')
  const hash = window.location.hash.slice(1)
  const targetAccordion = document.getElementById(hash)
  if (targetAccordion) {
    const offset = targetAccordion.getBoundingClientRect().top
    setTimeout(() => {
      window.scrollTo(0, window.scrollY + offset)
      window.scrollBy({
        top: less1024.matches ? -56 : -64,
        behavior: 'smooth',
      })
    }, 100)
  }
}

function getFAQsRaw(page: PageDataItemType) {
  let faqs: ParagraphAccordionItemFeData[] = []

  const accordionFe = page.relationships.field_dettaglio_tassonomia?.find(
    (paragraph) => paragraph.__typename === 'paragraph__accordion_fe'
  ) as ParagraphAccordionFeData | undefined

  if (accordionFe) {
    faqs = accordionFe.relationships?.field_accordion_item || []
  }

  // Look for single accordions
  if (faqs.length === 0) {
    const accordionItemFe =
      page.relationships.field_dettaglio_tassonomia?.filter(
        (component) => component.__typename === 'paragraph__accordion_item_fe'
      ) as ParagraphAccordionItemFeData[] | undefined

    if (accordionItemFe) {
      faqs = accordionItemFe || []
    }
  }

  return faqs
}

function getFAQs(page: PageDataItemType) {
  const accordionItems = getFAQsRaw(page)
  return accordionItems.map((faq) => ({
    title: faq.field_accordion_titolo,
    content: faq.field_accordion_descrizione?.processed || '',
  }))
}

function MobileMenuButton({
  label,
  onClick,
}: {
  label: string
  onClick: () => void
}) {
  return (
    <StyledMobileMenuButton onClick={onClick}>
      {label}
      <PlusIconTransparent />
    </StyledMobileMenuButton>
  )
}

export default function PageDefaultCategorieDomandeFrequenti(
  props: PageCategorieDataProps
) {
  const {
    data: { page },
    pageContext: { title },
  } = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const [faqData, setFaqData] = useState<FaqDataType[]>()
  const { feedbackAnswers, feedbackQuestion } = useFeedbackQA()

  const { data: faqsRes, isLoading: isLoadingFaqs } = useSWR(
    {
      key: 'faqs',
    },
    () =>
      fetch(
        `${removeTrailingSlashes(
          process.env.A2A_DRUPAL_URL
        )}/a2a-api/dashboard-faq-weight?_format=json`
      ).then((res) => res.json() as Promise<FaqDataType[]>)
  )

  const showRicerca = page.__typename === 'taxonomy_term__domande_frequenti_fe'

  // Find which faq is the active one from the data fetched from /a2a-api/dashboard-faq-weight and adds parent property
  const activeMenu = useMemo(() => {
    if (!faqData) return null

    // Search parents items
    const parentMenu = faqData?.find(
      (el) =>
        removeTrailingSlashes(el.path) ===
        removeTrailingSlashes(window.location.pathname)
    )
    if (parentMenu) {
      return { ...parentMenu, parent: '' }
    }

    // Search children items
    let childMenu: FaqDataChildType | undefined
    let parent = ''
    faqData.forEach((el) => {
      if (childMenu) return
      childMenu = el.children.find(
        (child) =>
          removeTrailingSlashes(child.path) ===
          removeTrailingSlashes(window.location.pathname)
      )
      parent = el.uuid
    })

    if (childMenu) {
      return { ...childMenu, parent }
    }

    return null
  }, [faqData])

  const isChild = useMemo(() => {
    return activeMenu && !!activeMenu.parent
  }, [faqData])

  // First-level menu items
  const parentsMenu: MenuLinkType[] = useMemo(
    () =>
      faqData
        ?.sort((a, b) => Number(a.weight) - Number(b.weight))
        .map((el) => ({
          name: el.title,
          url: el.path,
          id: el.uuid,
        })) || [],
    [faqData]
  )

  // Second-level menu items based on active parent
  const siblingsMenu: MenuLinkType[] = useMemo(() => {
    const menu =
      isChild &&
      faqData
        ?.find((x) => x.uuid === activeMenu?.parent)
        ?.children.sort((a, b) => Number(a.weight) - Number(b.weight))
        .map((x) => ({
          name: x.title,
          url: x.path,
          id: x.uuid,
        }))
    return menu || []
  }, [faqData])

  const pageTitle =
    page.relationships.field_dettaglio_tassonomia &&
    page.relationships.field_dettaglio_tassonomia[0] &&
    isParagraphTestoLibero(page.relationships.field_dettaglio_tassonomia[0]) &&
    page.relationships.field_dettaglio_tassonomia[0].field_testo_libero &&
    page.relationships.field_dettaglio_tassonomia[0].field_testo_libero
      .processed

  // Get all accordions data from graphql and integrate it with the
  // weight property with the data fetched from /a2a-api/dashboard-faq-weight
  const sortedAccordionData: SortedAccordionType[] = useMemo(
    () =>
      getFAQsRaw(page)
        .map((el) => {
          const accordion = activeMenu?.children.find(
            (x) => x.uuid === el.drupal_id
          )
          return {
            ...el,
            weight: Number(accordion?.weight),
            feedbackEnabled: accordion?.feedbackEnabled ?? false,
          }
        })
        .sort((a, b) => a.weight - b.weight),
    [faqData]
  )

  function MenuItems() {
    return (
      <>
        <StyledArgomentiTitle>Tutti gli argomenti</StyledArgomentiTitle>
        <StyledArgomentiWrapper>
          {parentsMenu.map((arg: MenuLinkType) => (
            <StyledArgomentoItem key={arg.id}>
              <StyledArgomentoLink
                to={arg.url}
                isActive={
                  !isChild &&
                  window.location.pathname.includes(
                    removeTrailingSlashes(arg.url)
                  )
                }
              >
                {arg.name}
              </StyledArgomentoLink>
              {isChild && arg.id === activeMenu?.parent && siblingsMenu && (
                <StyledArgomentoChildrenWrapper>
                  {siblingsMenu.map((argChild: MenuLinkType) => (
                    <StyledArgumentChild
                      key={argChild.id}
                      $isActive={window.location.pathname.includes(
                        removeTrailingSlashes(argChild.url)
                      )}
                    >
                      <StyledArgumentChildLink
                        to={argChild.url}
                        $isActive={window.location.pathname.includes(
                          removeTrailingSlashes(argChild.url)
                        )}
                      >
                        {argChild.name}
                      </StyledArgumentChildLink>
                    </StyledArgumentChild>
                  ))}
                </StyledArgomentoChildrenWrapper>
              )}
            </StyledArgomentoItem>
          ))}
        </StyledArgomentiWrapper>
      </>
    )
  }

  function MobileMenu() {
    return (
      <StyledMobileMenu>
        <MobileMenuButton label={title} onClick={() => setShowModal(true)} />
        <MobileMenuModal />
      </StyledMobileMenu>
    )
  }

  function DesktopMenu() {
    return (
      <StyledDesktopMenu>
        <MenuItems />
      </StyledDesktopMenu>
    )
  }

  const MobileMenuModal = () => {
    return (
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        fullScreen={true}
      >
        <MenuItems />
      </Modal>
    )
  }

  useEffect(() => {
    if (faqsRes) {
      setFaqData(faqsRes)
      scrollToHash()
    }
  }, [faqsRes])

  return (
    <A2ADataContextProvider>
      <Layout pageTitle={page.field_meta_tags_taxonomy_fe?.title || title}>
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-categorie-domande-frequenti',
              'a2a-navigation-main',
              'a2a-navigation-full'
            ),
          }}
        >
          {page.field_meta_tags_taxonomy_fe?.description && (
            <meta
              name="description"
              content={page.field_meta_tags_taxonomy_fe.description}
            />
          )}
        </Helmet>

        {!isChild && <MarkupFaqHead items={getFAQs(page)} />}

        <CanonicalUrlComponent url={page?.path?.alias} />

        <HeroImage
          backgroundColor="blue"
          title={page.name}
          breadcrumbLinks={
            page.relationships?.field_breadcrumb_content?.field_link_breadcrumb
              ? [
                  ...page.relationships.field_breadcrumb_content
                    .field_link_breadcrumb,
                  { title: page.name, uri: page.path.alias },
                ]
              : undefined
          }
        >
          <SearchInputComponent show={showRicerca} />
        </HeroImage>
        <StyledCategoriaWrapper>
          <Grid
            cols={1}
            desktopCols="1fr 2fr"
            colsDesktopGap="28px"
            rowsGap="32px"
            rowsDesktopGap="40px"
          >
            <DesktopMenu />
            <MobileMenu />
            <div>
              {!isChild &&
                activeMenu?.children &&
                activeMenu.children.length > 0 && (
                  <>
                    <StyledTitleWrapper
                      dangerouslySetInnerHTML={{ __html: pageTitle || title }}
                    />
                    <StyledChildrenWrapper>
                      {activeMenu.children
                        .sort((a, b) => Number(a.weight) - Number(b.weight))
                        .map((child) => (
                          <AtomAccordionLink
                            key={child.uuid}
                            label={child.title}
                            to={child.path || '/'}
                          />
                        ))}
                    </StyledChildrenWrapper>
                  </>
                )}
              {isChild &&
                page.relationships.field_dettaglio_tassonomia &&
                page.relationships.field_dettaglio_tassonomia.length > 0 && (
                  <StyledContentWrapper>
                    {page.relationships.field_dettaglio_tassonomia
                      .filter(
                        (el) =>
                          el.__typename !== 'paragraph__accordion_item_fe' &&
                          el.__typename !== 'paragraph__accordion_fe'
                      )
                      .map((child, i) => (
                        <DynamicComponent
                          key={i}
                          component={child.__typename}
                          data={child}
                          related={{
                            parent: {
                              __typename: page.__typename,
                            },
                            siblings: {
                              before:
                                page.relationships.field_dettaglio_tassonomia?.[
                                  i - 1
                                ] ?? undefined,
                              after:
                                page.relationships.field_dettaglio_tassonomia?.[
                                  i + 1
                                ] ?? undefined,
                            },
                          }}
                        />
                      ))}
                    {sortedAccordionData &&
                      sortedAccordionData.map((child) =>
                        child.field_show_feedback_only === true ? (
                          <StyledFeedbackFAQWrapper key={child.drupal_id}>
                            <FeedbackFAQ
                              faqID={child.drupal_id}
                              feedbackQuestion={feedbackQuestion}
                              feedbackAnswers={feedbackAnswers}
                              onDataLayer={() => {
                                eventDataLayer({
                                  event: 'click_faq',
                                  stream: 'web',
                                  label: prepareStringForDataLayer(
                                    child?.field_accordion_titolo
                                  ),
                                  azione: 'open',
                                  sezione: getGA4PageSezione(),
                                  element_id: child.drupal_id || '',
                                })
                              }}
                            />
                          </StyledFeedbackFAQWrapper>
                        ) : (
                          <Accordion key={child.drupal_id}>
                            <AccordionItem
                              className={AccordionTheme.Flat}
                              faqID={child.drupal_id}
                              head={child?.field_accordion_titolo}
                              showDesktop={
                                child.field_showdesktop === false ? false : true
                              }
                              showMobile={
                                child.field_showmobile === false ? false : true
                              }
                              hasFeedback={
                                child.feedbackEnabled
                                  ? child.feedbackEnabled
                                  : false
                              }
                              feedbackQuestion={feedbackQuestion}
                              feedbackAnswers={feedbackAnswers}
                            >
                              <Html>
                                {child?.field_accordion_descrizione?.processed}
                              </Html>
                            </AccordionItem>
                          </Accordion>
                        )
                      )}
                  </StyledContentWrapper>
                )}
            </div>
          </Grid>
        </StyledCategoriaWrapper>
        {page.relationships.field_doorways_banner &&
          typeof page.relationships.field_doorways_banner === 'object' &&
          !!page.relationships.field_doorways_banner.length &&
          !!page.relationships.field_doorways_banner[0] && (
            <StyledBannerWrapper>
              <DynamicComponent
                component={
                  page.relationships.field_doorways_banner[0].__typename
                }
                data={page.relationships.field_doorways_banner[0]}
                related={{
                  parent: undefined,
                }}
              />
            </StyledBannerWrapper>
          )}
        <LoaderModal showModal={isLoadingFaqs} fullScreen={false} />
      </Layout>
    </A2ADataContextProvider>
  )
}

interface PageDataItemType {
  __typename: string
  id: string
  drupal_id: string
  internal: {
    type: string
  }
  name: string
  path: {
    alias: string
  }
  field_meta_tags_taxonomy_fe?: {
    description: string | null
    title: string | null
  } | null
  field_domande_sottotitolo: {
    format: string
    processed: string
  }
  field_hub_emoving?: boolean | null
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_domande_icona: ParagraphSvgFeData | null
    field_doorways_banner?: ParagraphDoorwaysBannerFe[]
    field_dettaglio_tassonomia?: ComponentDataModel[] | null
    parent:
      | {
          id: string
          drupal_id: string
          name: string
          path: {
            alias: string
          }
        }[]
      | null
  }
}

interface PageCategorieDataProps {
  data: {
    page: PageDataItemType
    categories: {
      nodes: CategoriesDomandeFrequentiType[]
    }
  }
  pageContext: {
    id: string
    title: string
  }
}

type MenuLinkType = {
  name: string
  url: string
  id: string
}

type FaqDataType = {
  uuid: string
  title: string
  weight: string
  path: string
  children: FaqDataChildType[]
}

type FaqDataChildType = {
  uuid: string
  title: string
  weight: string
  path: string
  feedbackEnabled?: boolean
  children: {
    uuid: string
    title: string
    path?: string
    weight: string
    feedbackEnabled?: boolean
  }[]
}

type SortedAccordionType = ParagraphAccordionItemFeData & {
  weight: number
  feedbackEnabled: boolean
}
export const pageQuery = graphql`
  query ($id: String!) {
    page: taxonomyTermDomandeFrequentiFe(id: { eq: $id }) {
      __typename
      id
      drupal_id
      internal {
        type
      }
      name
      path {
        alias
      }
      field_meta_tags_taxonomy_fe {
        description
        title
      }
      field_domande_sottotitolo {
        format
        processed
      }
      field_hub_emoving
      relationships {
        field_breadcrumb_content {
          ...BreadcrumbsFe
        }
        field_domande_icona {
          ...ParagraphSvgFe
        }
        field_dettaglio_tassonomia {
          __typename
          ...ParagraphAccordionFe
          ...ParagraphAccordionItemFe
          ...ParagraphMarginiFe
          ...ParagraphTabsFe
          ...ParagraphTestoLiberoFe
        }
        parent {
          id
          drupal_id
          name
          path {
            alias
          }
        }
      }
    }
    categories: allTaxonomyTermDomandeFrequentiFe(
      sort: { fields: weight, order: ASC }
    ) {
      nodes {
        __typename
        id
        drupal_id
        internal {
          type
        }
        name
        path {
          alias
        }
        field_hub_emoving
        relationships {
          parent {
            id
            drupal_id
            name
            path {
              alias
            }
          }
        }
      }
    }
  }
`
